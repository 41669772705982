import React from 'react';
import { Link } from 'gatsby';

import '../styles/mobile_nav.scss';

const MobileNavMenu = ({isVisible, setIsVisible}) => {
    const onClick = () => setIsVisible(false);
    return(
        <div id='mobileNavMenu' className={isVisible ? 'visible' : 'invisible'}>
            <div className='mobileNavCloseButton' onClick={onClick}>
                x
            </div>
            <div className='mobileNavItem'>
                <Link to="/about" activeClassName='activeNavLink'>about</Link>
            </div>
            <div className='mobileNavItem'>
                <Link to="/where_when" activeClassName='activeNavLink'>where &amp; when</Link>
            </div>
            <div className='mobileNavItem'>
                <Link to="/shop" activeClassName='activeNavLink'>shop</Link>
            </div>
            <div className='mobileNavItem'>
                <Link to="/contact" activeClassName='activeNavLink'>contact</Link>
            </div>
        </div>
)};

export default MobileNavMenu;