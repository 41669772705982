import { Link } from 'gatsby';
import React from 'react';

import '../styles/desktop_nav.scss';

const DesktopNav = () => (
    <div className="desktopNav">
        <div className="nav-item"><Link to="/about">about</Link></div>
        <div className="nav-item"><Link to="/where_when">where &amp; when</Link></div>
        <div className="nav-item"><Link to="/shop">shop</Link></div>
        <div className="nav-item"><Link to="/contact">contact</Link></div>
    </div>
)

export default DesktopNav;